// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "m_pN d_gv d_cr";
export var heroHeaderCenter = "m_gw d_gw d_cr d_dv";
export var heroHeaderRight = "m_gx d_gx d_cr d_dw";
export var heroParagraphLeft = "m_pP d_gr d_cv";
export var heroParagraphCenter = "m_gs d_gs d_cv d_dv";
export var heroParagraphRight = "m_gt d_gt d_cv d_dw";
export var heroBtnWrapperLeft = "m_pQ d_d1 d_d0 d_v d_by d_bF";
export var heroBtnWrapperCenter = "m_pR d_d2 d_d0 d_v d_by d_bC";
export var heroBtnWrapperRight = "m_pS d_d3 d_d0 d_v d_by d_bG";
export var overlayBtnWrapper = "m_pT d_gq d_Z d_6 d_7 d_8 d_bm d_cb";
export var design4 = "m_pV d_gp d_Z d_6 d_7 d_bm";
export var heroExceptionSmall = "m_pW q_pW";
export var heroExceptionNormal = "m_pX q_pX";
export var heroExceptionLarge = "m_pY q_pY";
export var Title1Small = "m_pZ q_pZ q_qC q_qD";
export var Title1Normal = "m_p0 q_p0 q_qC q_qF";
export var Title1Large = "m_p1 q_p1 q_qC q_qG";
export var BodySmall = "m_p2 q_p2 q_qC q_qW";
export var BodyNormal = "m_p3 q_p3 q_qC q_qX";
export var BodyLarge = "m_p4 q_p4 q_qC q_qY";