// extracted by mini-css-extract-plugin
export var lbContainerOuter = "v_s6";
export var lbContainerInner = "v_s7";
export var movingForwards = "v_s8";
export var movingForwardsOther = "v_s9";
export var movingBackwards = "v_tb";
export var movingBackwardsOther = "v_tc";
export var lbImage = "v_td";
export var lbOtherImage = "v_tf";
export var prevButton = "v_tg";
export var nextButton = "v_th";
export var closing = "v_tj";
export var appear = "v_tk";