// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "r_rL d_bC";
export var storyRowWrapper = "r_hx d_hx d_bJ";
export var storyLeftWrapper = "r_rM d_by d_bN";
export var storyWrapperFull = "r_rN d_cC";
export var storyWrapperFullLeft = "r_nN d_cC d_by d_bN d_bC";
export var contentWrapper = "r_pb d_hy";
export var storyLeftWrapperCenter = "r_rP d_hz d_by d_bC d_bN d_bH";
export var storyRightWrapperCenter = "r_rQ d_hF";
export var storyHeader = "r_rR d_hD d_v d_cr";
export var storyHeaderCenter = "r_hC d_hC d_v d_cr d_dv d_bx";
export var storyParagraphCenter = "r_hB d_hB d_bx d_dv";
export var storyBtnWrapper = "r_rS d_d1 d_d0 d_v d_by d_bF";
export var storyBtnWrapperCenter = "r_hJ d_hJ d_d0 d_v d_by d_bC";
export var imageWrapper = "r_rT d_ff d_Y";
export var imageWrapperFull = "r_rV d_v d_G d_bd d_Y";